
import { AWS_TRANSCRIPT_LANGUAGES } from '@/constants/transcribe'

export default {
  props: {
    isShowModal: {
      type: Boolean,
      default: false,
    },
    fileExtension: {
      type: String,
      default: '',
    },
  },

  components: {
    ModalBox: () => import('@/components/modals/ModalBox.vue'),
    FilledButton: () => import('@/components/buttons/FilledButton'),
  },

  data: () => ({
    selectedCountryLanguage: { lang: 'US English', code: 'en-US' },
    isOpenDropList: false,
  }),

  computed: {
    transcriptLanguages() {
      if (!this.fileExtension) {
        return []
      }
      return AWS_TRANSCRIPT_LANGUAGES
    },
  },
}
