// AWS supports one more encoding "amr" but it's not supported by the browser.
export const AWS_ALLOWED_ENCODINGS = ['mp3', 'm4a', 'mp4', 'ogg', 'flac', 'webm', 'wav']

export const AWS_TRANSCRIPT_LANGUAGES = [
  { lang: 'Gulf Arabic', code: 'ar-AE' },
  { lang: 'Abkhaz', code: 'ab-GE' },
  { lang: 'Afrikaans', code: 'af-ZA' },
  { lang: 'Arabic, Gulf', code: 'ar-AE' },
  { lang: 'Arabic, Modern Standard', code: 'ar-SA' },
  { lang: 'Armenian', code: 'hy-AM' },
  { lang: 'Asturian', code: 'ast-ES' },
  { lang: 'Azerbaijani', code: 'az-AZ' },
  { lang: 'Bashkir', code: 'ba-RU' },
  { lang: 'Basque', code: 'eu-ES' },
  { lang: 'Belarusian', code: 'be-BY' },
  { lang: 'Bengali', code: 'bn-IN' },
  { lang: 'Bosnian', code: 'bs-BA' },
  { lang: 'Bulgarian', code: 'bg-BG' },
  { lang: 'Catalan', code: 'ca-ES' },
  { lang: 'Central Kurdish, Iran', code: 'ckb-IR' },
  { lang: 'Central Kurdish, Iraq', code: 'ckb-IQ' },
  { lang: 'Chinese, Simplified', code: 'zh-CN' },
  { lang: 'Chinese, Traditional', code: 'zh-TW' },
  { lang: 'Croatian', code: 'hr-HR' },
  { lang: 'Czech', code: 'cs-CZ' },
  { lang: 'Danish', code: 'da-DK' },
  { lang: 'Dutch', code: 'nl-NL' },
  { lang: 'English, Australian', code: 'en-AU' },
  { lang: 'English, British', code: 'en-GB' },
  { lang: 'English, Indian', code: 'en-IN' },
  { lang: 'English, Irish', code: 'en-IE' },
  { lang: 'English, New Zealand', code: 'en-NZ' },
  { lang: 'English, Scottish', code: 'en-AB' },
  { lang: 'English, South African', code: 'en-ZA' },
  { lang: 'English, US', code: 'en-US' },
  { lang: 'English, Welsh', code: 'en-WL' },
  { lang: 'Estonian', code: 'et-ET' },
  { lang: 'Farsi', code: 'fa-IR' },
  { lang: 'Finnish', code: 'fi-FI' },
  { lang: 'French', code: 'fr-FR' },
  { lang: 'French, Canadian', code: 'fr-CA' },
  { lang: 'Galician', code: 'gl-ES' },
  { lang: 'Georgian', code: 'ka-GE' },
  { lang: 'German', code: 'de-DE' },
  { lang: 'German, Swiss', code: 'de-CH' },
  { lang: 'Greek', code: 'el-GR' },
  { lang: 'Gujarati', code: 'gu-IN' },
  { lang: 'Hausa', code: 'ha-NG' },
  { lang: 'Hebrew', code: 'he-IL' },
  { lang: 'Hindi, Indian', code: 'hi-IN' },
  { lang: 'Hungarian', code: 'hu-HU' },
  { lang: 'Icelandic', code: 'is-IS' },
  { lang: 'Indonesian', code: 'id-ID' },
  { lang: 'Italian', code: 'it-IT' },
  { lang: 'Japanese', code: 'ja-JP' },
  { lang: 'Kabyle', code: 'kab-DZ' },
  { lang: 'Kannada', code: 'kn-IN' },
  { lang: 'Kazakh', code: 'kk-KZ' },
  { lang: 'Kinyarwanda', code: 'rw-RW' },
  { lang: 'Korean', code: 'ko-KR' },
  { lang: 'Kyrgyz', code: 'ky-KG' },
  { lang: 'Latvian', code: 'lv-LV' },
  { lang: 'Lithuanian', code: 'lt-LT' },
  { lang: 'Luganda', code: 'lg-IN' },
  { lang: 'Macedonian', code: 'mk-MK' },
  { lang: 'Malay', code: 'ms-MY' },
  { lang: 'Malayalam', code: 'ml-IN' },
  { lang: 'Maltese', code: 'mt-MT' },
  { lang: 'Marathi', code: 'mr-IN' },
  { lang: 'Meadow Mari', code: 'mhr-RU' },
  { lang: 'Mongolian', code: 'mn-MN' },
  { lang: 'Norwegian Bokmål', code: 'no-NO' },
  { lang: 'Odia/Oriya', code: 'or-IN' },
  { lang: 'Pashto', code: 'ps-AF' },
  { lang: 'Polish', code: 'pl-PL' },
  { lang: 'Portuguese', code: 'pt-PT' },
  { lang: 'Portuguese, Brazilian', code: 'pt-BR' },
  { lang: 'Punjabi', code: 'pa-IN' },
  { lang: 'Romanian', code: 'ro-RO' },
  { lang: 'Russian', code: 'ru-RU' },
  { lang: 'Serbian', code: 'sr-RS' },
  { lang: 'Sinhala', code: 'si-LK' },
  { lang: 'Slovak', code: 'sk-SK' },
  { lang: 'Slovenian', code: 'sl-SI' },
  { lang: 'Somali', code: 'so-SO' },
  { lang: 'Spanish', code: 'es-ES' },
  { lang: 'Spanish, US', code: 'es-US' },
  { lang: 'Sundanese', code: 'su-ID' },
  { lang: 'Swahili, Kenya', code: 'sw-KE' },
  { lang: 'Swahili, Burundi', code: 'sw-BI' },
  { lang: 'Swahili, Rwanda', code: 'sw-RW' },
  { lang: 'Swahili, Tanzania', code: 'sw-TZ' },
  { lang: 'Swahili, Uganda', code: 'sw-UG' },
  { lang: 'Swedish', code: 'sv-SE' },
  { lang: 'Tagalog/Filipino', code: 'tl-PH' },
  { lang: 'Tamil', code: 'ta-IN' },
  { lang: 'Tatar', code: 'tt-RU' },
  { lang: 'Telugu', code: 'te-IN' },
  { lang: 'Thai', code: 'th-TH' },
  { lang: 'Turkish', code: 'tr-TR' },
  { lang: 'Ukrainian', code: 'uk-UA' },
  { lang: 'Uyghur', code: 'ug-CN' },
  { lang: 'Uzbek', code: 'uz-UZ' },
  { lang: 'Vietnamese', code: 'vi-VN' },
  { lang: 'Welsh', code: 'cy-WL' },
  { lang: 'Wolof', code: 'wo-SN' },
  { lang: 'Zulu', code: 'zu-ZA' },
]
